import React from "react";
import {Row} from 'react-bootstrap'
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
import customdev1 from "@/images/customdev1.png";
import customdev2 from "@/images/customdev2.jpg";

const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={customdev1} alt="" />
      <h2>Web, Mobile or Desktop Solutions For Businesses And Enterprises</h2>
      <p>
        Kloudxel believes in partnering with you to engineer and develop the best mobile, 
        web, and desktop solutions tailored according to your requirements that propel your 
        digital growth to new heights. Whether you are looking for a custom website, 
        mobile app, full-fledged desktop application, web services, or a complete web architecture, 
        Kloudxel delivers your solution through a trustworthy and reliable software development 
        lifecycle, from requirement gathering, planning and design, development and deployment 
        to testing, maintenance and support.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={customdev2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Custom Business Application Services</h3>
          <p>
            We offer the following services:
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Web Development And Services
            </li>
            <li>
              <i className="fa fa-check-square"></i>Progressive Web Applications
            </li>
            <li>
              <i className="fa fa-check-square"></i>Native Mobile Applications
            </li>
            <li>
              <i className="fa fa-check-square"></i>Hybrid Mobile Applications
            </li>
            <li>
              <i className="fa fa-check-square"></i>Desktop Applications
            </li>
          </ul>
        </div>
      </div>
      <p>
        Each application and technology solution is developed by our professionals 
        using research, planning, and design techniques, agile development methods, 
        and the present era’s pioneering technology stacks and platforms. 
      </p>
      
    </div>
  );
};

export default ServiceDetailsContent;
