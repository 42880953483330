import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServiceSidebar from "@/components/service-sidebar";
import ServiceDetailsContent from "@/components/service-details-content";
import angular from "@/images/angular.png";
import reactimg from "@/images/react.png";
import vue from "@/images/vue.png";
import bootstrap from "@/images/bootstrap.png";
import nodejsimg from "@/images/nodejs.png";
import dotnet from "@/images/aspnet.png";
import python from "@/images/python.png";
import postgre from "@/images/postgresql.png";
import mysql from "@/images/mysql.png";
import mongodb from "@/images/mongodb.png";
import mssql from "@/images/mssql.png";
//import sqlite from "@/images/sqlite.png";
import "typeface-oswald";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/animate.css";
import "@/css/font-awesome.min.css";
import "@/css/icons.css";
import "@/css/preset.css";
import "@/css/theme.css";
import "@/css/responsive.css";

const ServiceDetails = () => {
  return (
    <section className="commonSection service_detail">
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <ServiceDetailsContent />
          </Col>
          <Col lg={4} md={12} className="sidebar">
            <ServiceSidebar />
          </Col>
        </Row>
        <Row>
        <div className="tech-stack col-lg-12">
        <h3>Tech Stacks We Use</h3>
          <div className="tech-stack-container ">
            <div class="tech-stack-item">
            <img src={angular} alt="" />
            </div>
            <div class="tech-stack-item" >
              <img src={reactimg} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={vue} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={bootstrap} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={nodejsimg} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={dotnet} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={python} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={postgre} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={mysql} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={mongodb} alt="" />
            </div>
            <div class="tech-stack-item">
              <img src={mssql} alt="" />
            </div>
          </div>
      </div>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceDetails;
